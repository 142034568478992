//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import countdown from '../../utils/countdown'
import PageHeader from '../../layout/components/Header'
export default {
    name: 'Login',
	components: {PageHeader},
    data() {
        return {
			webset:[],
			uniacid:0,
			loginMethod:'password',
            address: [
                {text: "香港",value: "852"},
                {text: "澳门",value: "853"},
                {text: "中国大陆",value: "86"},
		    ],
            form: {
                mobile: '',
                diqu: '852',
                password: '',
                repassword: '',
                code: '',
                action: 'login'
            },
            sendCodeText: '發送驗證碼',
			facebooklogin:false 
           
        }
    },
	created() {
		var ss = window.localStorage.getItem('webinfo');
		var ssarray = JSON.parse(ss)
		 
		this.webset =  ssarray
		this.uniacid = ssarray['ucid']
		if(ssarray['openfacebook']>0){
			this.facebooklogin = true 
		}
		this.form.uniacid = this.uniacid
		
		
	},
    methods: {
        // 发送驗證碼
        async sendMobileCode() {
            console.log(this.$http)
            if (!this.form.mobile) return this.$message({ message: '請輸入手機號',type: 'warning'});
            const res = await this.$http.post('/validate/sendMobileCode', this.form)
            if (res.code !== 0) return this.$message.error(res.message)
            let data = res.data
            this.form.code = data.code
            countdown.start(data.timer, (d, s) => {
                this.sendCodeText = Number(s).toFixed(0) + 's'
            }, () => {
                this.sendCodeText = '驗證碼'
            })
        },
        // 手机号登录
        async loginSubmit() {
            if (!this.form.mobile) return this.$message({ message: '請輸入手機號',type: 'warning'});
            if (this.loginMethod == 'password' && !this.form.password) return this._showToast('請輸入密碼');
            if (this.loginMethod == 'mobile' && !this.form.code) return this.$message({ message: '請輸入驗證碼',type: 'warning'});
            const api = '/login' + (this.loginMethod == 'mobile' ? '/sms' : '')
            const res = await this.$http.post(api, this.form)
            window.localStorage.setItem('user_id', res.data.user_id);
            if (res.code !== 0) return this.$message.error(res.message)
            this._loginSuccessCallback(res.data, true)
        },  // 手机号reg
		
        async regSubmit() {
            if (!this.form.mobile) return this.$message({ message: '請輸入手機號',type: 'warning'});
            if (this.loginMethod == 'password' && !this.form.password) return this._showToast('請輸入密碼');
         //   if (this.loginMethod == 'mobile' && !this.form.code) return this.$message({ message: '請輸入驗證碼',type: 'warning'});
            const api = '/user/register';// + (this.loginMethod == 'mobile' ? '/sms' : '')
            const res = await this.$http.post(api, this.form)
			console.log(res);
			
			this.$message({ message: '注册成功',type: 'success'});
			
			return
            window.localStorage.setItem('user_id', res.data.user_id);
            if (res.code !== 0) return this.$message.error(res.message)
          //  this._loginSuccessCallback(res.data, true)
        },
		
	    forgetpwd(){
			this.$message({ message: '請聯係系統管理員',type: 'success'});
		},

        // 登录成功
        _loginSuccessCallback(data, back) {
            window.localStorage.setItem('login@token', data.token)
            document.cookie = 'PHPSESSID=' + data.token
            window.localStorage.setItem('user_id', data.user_id)
            this.getUserInfo()
            window.location.hash = '/'
        },

        //获取用户信息
        async getUserInfo(e) {
            const user_id = window.localStorage.getItem('user_id') || ''
            let res = null
            if(user_id){
                res = await this.$http.get('/user/profile',{user_id})
            }
            user_id && res.code == 0 && this.$store.commit('saveUserInfo',res.data)
        },
		
		 async onSignInSuccess (response) {
		        // FB.api('/me', dude => {
		        //   console.log(`Good to see you, ${dude.name}.`)
		        // })
				const token = response.authResponse.accessToken
				const userID = response.authResponse.userID
				
		        console.log(response) //返回第三方的登录信息 tolen等
		        console.log('token',token) //返回第三方的登录信息 tolen等
				
				let  fdata= {
						userid: userID
					}
					 
	            let  res = await this.$http.get('/login/facebooklogin',fdata)
				  
				  console.log('email res',res );
				  
				  if (res.code !== 0) return this.$message.error(res.message)
				  this._loginSuccessCallback(res.data, true)
				  
				
		},
		// 获取facebook用户信息
		getFacebookUserinfo(params) {
		  return request({
		    url: '/app/index.php?i=1&c=entry&m=qicheng_messenger&do=api_facebook_info',
		    method: 'get',
		    params
		  })
		},
		onSignInError (error) {
			
		      }
    }
}
